import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ICPOSSessionInfo } from '../../../shared/models';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
})
export class LogoutModalComponent implements OnInit, OnDestroy {
  public logoutTimer$: Subscription; // Holds the countdown observable
  public counter: number; // Log out after this many seconds
  public sessionInfo: ICPOSSessionInfo;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_DIALOG_DATA) public dataAlt: any
  ) {}

  ngOnInit() {
    this.counter = this.data.modalDuration; // How long to display the modal window

    // Create a timer observable that counts down
    this.logoutTimer$ = interval(1000).subscribe(() => {
      // If timer is greater than 0, count down.
      if (this.counter > 1) {
        this.counter--;
      } else {
        const idleTimeout = this.data.sessionExpirationSeconds;
        // If timer hits zero or below, CLOSE this modal which toggles the logout action in AuthService
        this.logout(idleTimeout);
      }
    });
  }

  /** Log the user out manually */
  public logout(idleTimeout?: number) {
    this.populateSessionInfo(true, idleTimeout);
    this.dialogRef.close(this.sessionInfo);
  }

  public extendSession() {
    this.populateSessionInfo(false);
    this.dialogRef.close(this.sessionInfo);
  }

  public ngOnDestroy() {
    this.logoutTimer$.unsubscribe(); // Unsub from timer on modal close
  }

  private populateSessionInfo(reason: boolean, idleTimeout?: number) {
    this.sessionInfo = {
      reason: reason,
      idleTimeout: idleTimeout
    };
  }
}
