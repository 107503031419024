import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
// import { environment } from '$env';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { BehaviorSubject, Subscription, Subject, merge } from 'rxjs';
import { ApiService } from '$api';
import { debounceTime, switchMap, tap, startWith } from 'rxjs/operators';
import { AuthService, AppSettings } from '$shared';
import { guidsAdd } from '../utils/guidsAdd.util';
import { escape } from 'lodash';
import {mapFormBuilderToLoanModel} from '../utils/mapFormBuilderToLoan.util';
import { cloneDeep, isArray, isObject } from 'lodash';
import { defaultModels } from '../../shared/models.defaults';
import {
  ILookupItem,
  IAssetViewModel,
  ILoanViewModel,
  ICPOSForm1003State,
  ICPOSAppState,
  LoanPurposeTypeEnum,
  ICPOSLoanViewModel,
} from 'src/app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  /** megaSave queue (1 item long) */
  private loanToSave: ICPOSLoanViewModel = null;
  get hasLoanQueued() {
    return !!this.loanToSave;
  }

  /** Is a save currently in progress */
  private _isSaving = false;
  public isSaving$ = new BehaviorSubject(false);
  public get isSaving(): boolean {
    return this._isSaving;
  }
  public set isSaving(value: boolean) {
    this.isSaving$.next(value);
    this._isSaving = value;
  }

  public dataFields$ = new BehaviorSubject<Record<string | number, any[]>>({});
  private dataFields: Record<string | number, any[]> = {};

  /** Any time a megasave completes, the api response will be passed into this subject  */
  public megaSaveResponse$ = new Subject<ILoanViewModel>();
  /** Fires when all saving is complete including this.loanToSave  */
  public saveComplete$ = new Subject<boolean>();

  /** Used to prevent infinite patch loops with multiple valueChanges  */
  public isChangingData = false;

  public models = defaultModels;

  constructor(
    private title: Title,
    private fb: FormBuilder,
    private api: ApiService,
    private auth: AuthService,
    private settings: AppSettings,
  ) {}

  /**
   * Generate state for the 1003
   * @param sections
   */
  public createForm1003State(
    sections: CvFormBuilder.SectionControl[],
    appState: ICPOSAppState,
    activePageId: string,
  ): ICPOSForm1003State[] {
    if (!sections) {
      return;
    }

    const sectionState: Record<string, ICPOSForm1003State> = {};
    appState.form1003.state.forEach(section => (sectionState[section.sectionId] = section));
    return sections.map(section => {
      const result = <ICPOSForm1003State>{
        sectionId: section.sectionId,
        lastPageId: section.isActive ? activePageId : null,
        isComplete: section.isComplete,
        isActive: section.isActive,
        hasSummary: section.hasSummary,
        title: section.title,
      };
      if (sectionState && sectionState[section.sectionId] && sectionState[section.sectionId].isComplete) {
        result.isComplete = true;
      }
      // Inherit lastpageId if not overridden by argument, use original
      if (!result.lastPageId && sectionState[section.sectionId] && sectionState[section.sectionId].lastPageId) {
        result.lastPageId = sectionState[section.sectionId].lastPageId;
      }
      return result;
    });
  }

  /**
   * Prepare loan for saving
   */
  public prepareLoanForSaving(form1003: FormGroup): ILoanViewModel {
    // Add guids to all models that are missing them
    // Add them to the reactive form to ensure they are only set once and do not change each save
    guidsAdd(form1003.get('loan'), this.api.guids);
    return mapFormBuilderToLoanModel(
      form1003.getRawValue().loan,
      this.models,
      this.settings.urla,
      this.settings.clientId,
      this.settings.userId
    );
  }

  /**
   * Save the megaloan.
   * Supports queuing so only one loan can be saved at a time and if one is in queue it will save after the current one completes
   *
   * @param megaLoan
   */
  public megaLoanSave(megaLoan: ILoanViewModel) {
    this.settings.criticalSave = true;

    // Is a save or ping service (from auth.service.ts) currently in progress
    // Ping service can issue new OAuth token and if save is executing simultaneously
    // it can fail with 401 - Unauthorized error because current token is not valid anymore.
    if (this.isSaving || this.auth.isPinging) {
      // Set this loan to save next
      this.loanToSave = cloneDeep(megaLoan);
    } else {
      this.isSaving = true;
      // Start loan saving

      let megaSave$ = this.api.megaLoan.save(megaLoan);

      // If closing date is missing or unset, but Loan Purpose IS set...
      if (
        (!(megaLoan && megaLoan.closingDate && megaLoan.closingDate.dateValue) ||
          megaLoan.closingDate.dateValue === '0001-01-01T00:00:00') &&
        !!megaLoan.loanPurposeType
      ) {
        const loanPurpose = megaLoan.loanPurposeType === LoanPurposeTypeEnum.Purchase ? 'Purchase' : 'Refinance';
        megaSave$ = this.api.services.getClosingDate(loanPurpose).pipe(
          switchMap(closingDate => {
            const updatedClosingDate = { ...megaLoan.closingDate, ...{ dateValue: closingDate.dateValue } };
            const updatedMegaLoan = { ...megaLoan, ...{ closingDate: updatedClosingDate } };
            return this.api.megaLoan.save(updatedMegaLoan);
          }),
        );
      }

      megaSave$.subscribe(
        megaLoanResponse => {
          // On complete
          this.isSaving = false;
          // Feature 383985 - Get Loan Number Call on Six Pieces if Get Loan Number Vendor Product enabled
          if (megaLoanResponse.primaryAppSixPieces) {
            this.settings.primaryAppSixPieces = true;
          }
          this.megaSaveResponse$.next(megaLoanResponse);
          // If a loan is queued, immediate start saving it
          if (this.loanToSave) {
            this.megaLoanSave(this.loanToSave);
            this.loanToSave = null; // After passing loan to method, set to null
          } else {
            // Current loan completes and no loan is queued
            this.saveComplete$.next(true);
          }
          this.settings.criticalSave = false;
        },
        error => {
          this.settings.criticalSave = false;
          // On error
          this.isSaving = false;
          this.saveComplete$.error(error);
          console.error('Megasave fail', error);
          if (error.status === 401) {
            this.auth.logOut();
          }
        },
      );
    }
  }

  /**
   * Change the page title
   */
  public pageTitleUpdate(page: any, section: any) {
    const sanitizeText = (text: string, keepPunctuation: boolean) => {
      if (!text) {
        return text;
      }

      return keepPunctuation
        ? escape(text)
        : text.replace(/<(?:.|\n)*?>/gm, '').replace(/[.,\/#!$%\^&\*?;:{}=_`~()]/gi, '');
    };
    let title = '';
    if (page) {
      const pageTitle = sanitizeText(page.htmlTitle || page.title, page.htmlTitlePunctuation);
      if (pageTitle) {
        title += pageTitle;
      }
    }
    if (!!title) {
      title += ' - ';
    }
    if (section) {
      const sectionTitle = sanitizeText(section.htmlTitle || section.title, section.htmlTitlePunctuation);
      if (sectionTitle) {
        title += sectionTitle;
      }
    }
    // title += environment.properties.appName;
    // Add delay to override default title service in app.component
    setTimeout(() => this.title.setTitle(title));
  }

  /**
   * Load all the enumerations/datafields needed by a specific section
   * @param sectionId
   */
  public sectionGetDataFields(sectionId: string) {
    let dataFields: number[] = [];
    switch (sectionId) {
      case 'loan-purpose':
        dataFields = [140, 108];
        break;
      case 'property':
        dataFields = [89, 192, 219];
        break;
      case 'personal':
        dataFields = [42, 150, 219];
        break;
      case 'assets':
        dataFields = [26];
        break;
      case 'income':
        dataFields = [91, 219];
        break;
      case 'demographics':
        dataFields = [95, 196, 495, 496, 498];
        break;
    }
    // 150 - married
    // 179 - Home/Cell/work
    // 185 - primary residence/investment/second home
    // 219 - States
    // 199 - Base pay, overtime pay, bonuses, commissions
    // 154 - Military pay

    if (dataFields.length) {
      dataFields.forEach(num => this.dataFieldsGet(num));
    }
  }

  /**
   * Load a datafield into the dictionary
   * TODO: Flatten with combineLatest?
   * @param id
   */
  public dataFieldsGet(id: number) {
    //  | number[]
    const slug = 'df-';
    // const dfArray = Array.isArray(id) ? id : [id];
    if (this.dataFields[slug + id]) {
      return;
    }
    // Get datafield from web api
    this.api.dataFields.get(id).subscribe((res: ILookupItem[]) => {
      // Map to format needed by the dictionary
      const value = res
        .filter(val => {
          if (id === 26 && val.orderValue < 0) {
            // filter out 'Not Required' for asset type
            return false;
          }
          return true;
        })
        .map(val => {
          // Use text labels instead of values
          if ([219, 495, 496, 498].includes(id)) {
            return {
              ...val,
              value: val.text,
              label: val.text,
            };
          } else {
            return {
              ...val,
              value: val.enumValue,
              label: val.text,
            };
          }

          /**
        switch (id) {
          // States
          case 219:
            return {
              ...val,
              value: val.text,
              label: val.text,
            };
          // Everything else
          default:
            return {
              ...val,
              value: val.enumValue,
              label: val.text,
            };
        }
         */
        });
      // Add to dictionary
      this.dataFieldsAdd(slug + id, value);
    });
    return this.dataFields$;
  }

  /**
   * Add a datafield entry to the dictionary and observable
   * @param id
   * @param value
   */
  public dataFieldsAdd(id: string, value: any[], cache = true) {
    // console.log('dataFieldsAdd', id, value, cache);
    // If already exists, do nothing
    if (this.dataFields[id] && cache) {
      return;
    }

    const dataFields = { ...this.dataFields };
    dataFields[id] = [...value];
    this.dataFields = dataFields;
    this.dataFields$.next(dataFields);
  }

  /**
   * Automatically generate a form group complete with controls. Will recurse through nested objects/arrays.
   * @param model - An object or JSON of the model. Can contain nested objects and arrays
   * @param defaultRequired - Should all fields be required. Default is false
   */
  public formGroupCreate(model: any, defaultRequired = false): FormGroup {
    // console.log('formGroupCreate', model);
    const formModel: any = {};
    // Loop through all props in the model
    Object.keys(model).forEach(key => {
      // Delete props from loan modal that cause saving to fail

      if (typeof model[key] === 'object' && Array.isArray(model[key]) && !model[key].length) {
        // model[key] = null;
      }

      if (
        typeof model[key] === 'object' &&
        !Array.isArray(model[key]) &&
        model[key] &&
        !Object.keys(model[key]).length
      ) {
        // model[key] = null;
      }

      if (
        key === 'interview' ||
        key === 'mainLoanApplication' ||
        key === 'propertyExpenses' ||
        key === 'closingDateHistories'
      ) {
        // model[key] = null;
      }

      if (model[key] === null) {
        // console.log('Deleting', key, model[key])
        // delete model[key];
      }

      // If this is a nested object, recurse to create form group
      if (model[key] && typeof model[key] === 'object' && !Array.isArray(model[key])) {
        formModel[key] = this.formGroupCreate(model[key]);
      } else if (model[key] && typeof model[key] === 'object' && Array.isArray(model[key])) {
        // If this is an array, recurse to create a form array
        const formArray: any[] = [];
        model[key].forEach((item: any) => formArray.push(this.formGroupCreate(item)));
        formModel[key] = this.fb.array(formArray);
      } else {
        // Standard value
        formModel[key] = defaultRequired ? [null, [Validators.required]] : [null, []];
      }
    });

    // If iterating inside an array of primitives, return a form control for the primitive
    if (typeof model === 'string' || typeof model === 'number' || typeof model === 'boolean') {
      return <any>this.fb.control(null);
    }

    return this.fb.group(formModel);
  }
  // The FormGroup generated breaks certain fields defined in the sections, need to fix them
  fixFormGroup(formGroup: FormGroup, sections: (CvFormBuilder.Section & { isComplete: boolean })[]) {
    const walk = (object: any, cb: (obj: any, path: any[], parent?: any) => void) => {
      const _walk = (obj: any, path: any[], parent: any) => {
        cb(obj, path, parent);
        if (isArray(obj)) {
          obj.forEach((value, index) => _walk(value, [...path, index], obj));
        } else if (isObject(obj)) {
          Object.entries(obj).forEach(([key, value]) => _walk(value, [...path, key], obj));
        }
      };

      _walk(object, [], null);
    };
    const forEachContent = (obj: any, cb: (content: any) => void) =>
      walk(obj, (o, p) => {
        if (isArray(o) && p[p.length - 1] === 'content') {
          o.forEach(c => cb(c));
        }
      });

    forEachContent(sections, c => {
      if (c.type === 'formField' && c.formFieldType === 'select' && c.multiple) {
        const [, parentName, controlName] = c.field.match(/(.+)\.([^.]+)/);
        const parent = formGroup.get(parentName) as FormGroup;
        const { validator, asyncValidator, updateOn } = formGroup.get(c.field) as AbstractControl;
        parent.setControl(controlName, new FormControl({ validator, asyncValidator, updateOn }));
      }
    });
  }
  /**
   * Generate a datafield from borrower information
   * @param form
   * @param subs
   */
  public borrowerDatafieldAdd(form: FormGroup, subs: Subscription[]) {
    const borrowerPrimary = form
      .get('loan')
      .get('transactionInfo')
      .get('borrowers')
      .get('0');
    const borrowerSecondary = form
      .get('loan')
      .get('transactionInfo')
      .get('borrowers')
      .get('1');

    // When a borrower name changes, update borrowers datafield
    const borrowers = merge(
      borrowerPrimary.get('firstName').valueChanges,
      borrowerPrimary.get('lastName').valueChanges,
      borrowerSecondary.get('firstName').valueChanges,
      borrowerSecondary.get('lastName').valueChanges,
    );
    subs.push(
      borrowers.pipe(debounceTime(250)).subscribe(() => {
        this.dataFieldsAdd(
          'borrowers',
          [
            {
              value: borrowerPrimary.value.borrowerId,
              label: borrowerPrimary.value.firstName + ' ' + borrowerPrimary.value.lastName,
            },
            {
              value: borrowerSecondary.value.borrowerId,
              label: borrowerSecondary.value.firstName + ' ' + borrowerSecondary.value.lastName,
            },
            {
              value: 'joint',
              label: 'Joint Account',
            },
          ],
          false,
        );
      }),
    );
  }

  /**
   * Handle all the one off form changes
   * @param loanForm
   * @param subs
   */
  public sideEffects(loanForm: FormGroup, subs: Subscription[]) {
    // If a user changes their marital status from married/separated to unmarried, reset isSpouseOnTheLoan
    subs.push(
      loanForm.get('loan.transactionInfo.borrowers.0.maritalStatus').valueChanges.subscribe(val => {
        if (val === 2) {
          loanForm.get('loan.transactionInfo.loanApplications.0.isSpouseOnTheLoan').patchValue(false);
        }
      })
    );

    // Hold a record of assets organized by assetId
    // This record is used to check for changes to the original ownerId
    let assetsRecord: Record<string, IAssetViewModel> = {};
    // Hold reference to assets form array
    const assetsControl = loanForm.get('loan.$$custom.loan.assets');
    subs.push(
      assetsControl.valueChanges
        .pipe(
          debounceTime(100),
          startWith(loanForm.get('loan.$$custom.loan.assets').value),
        )
        .subscribe((assets: IAssetViewModel[]) => {
          // Loop through all assets
          assets.forEach((asset, i) => {
            // Get previous asset record to compare against
            const assetPrev = assetsRecord[asset.assetId];
            // If the previous asset has a different asset than the new asset then the owner changed
            if (assetPrev && assetPrev.ownerId !== asset.ownerId && !asset.voaBalanceDate) {
              const assetControl = assetsControl.get(String(i)) as FormGroup;
              // If this form control does not exist, add it
              if (!assetControl.get('previousOwnerId')) {
                assetControl.addControl('previousOwnerId', new FormControl());
              }
              // Update previous owner to old owner
              assetControl.get('previousOwnerId').patchValue(assetPrev.ownerId, { emitEvent: false });
            }
          });
          // Reset record for any changes
          assetsRecord = {};
          assetsControl.value.forEach((asset: IAssetViewModel) => (assetsRecord[asset.assetId] = asset));
        }),
    );
  }

  /**
   * Automatically prefill city/state when the zip code is filled out
   * Adds a subscriber to all zipcodes that uses a lookup service
   * TODO: Get rid of this function and use a feature component
   * @param loanForm
   */
  public zipCodeAutofill(loanForm: FormGroup, subs: Subscription[]) {
    // Get all properties
    const propLocation = loanForm.get('loan.$$custom.loan');

    // Watch changes to primary borrower current address
    // If isSameAsPrimaryBorrowerCurrentAddress is set to true, update secondary borrower current address
    subs.push(
      propLocation
        .get('borrowerPrimary.addressCurrent')
        .valueChanges.pipe(
          tap(() => {
            const coBorrowerSameAddress = loanForm.getRawValue().loan.$$custom.loan.borrowerSecondary
              .isAddressSameAsPrimaryBorrower;

            // const coBorrowerAddress = loanForm.getRawValue().loan.$$custom.loan.borrowerSecondary.addressCurrent;
            if (coBorrowerSameAddress) {
              this.spouseAddressPatchValue(loanForm);
            }
          }),
        )
        .subscribe(),
    );

    // let incomeProps: CvEmployment[] = [];

    /**
     * Manage zip code lookup for incomes
     * Incomes is more complex because of a constantly changing array any

    propLocation.get('employments').valueChanges.subscribe((incomes: CvEmployment[]) => {
      // console.warn('incomeProps', incomeProps);
      incomes.forEach((income, i) => {
        if (
          income &&
          incomeProps[i] &&
          income.employerInfo.zip !== incomeProps[i].employerInfo.zip &&
          income.employerInfo.zip.length === 5
        ) {
          this.api.services.zipCodeLookup(income.employerInfo.zip, this.settings.loUserId).subscribe(res => {
            if (res) {
              // The zip lookup response is necessary to check for servicable zip codes
              if (!this.isChangingData) {
                this.isChangingData = true;
                const property = propLocation.get('employments').get(String(i));
                property.get('employerInfo.city').patchValue(res.cityName, { emitEvent: false });
                property.get('employerInfo.state').patchValue(res.stateName, { emitEvent: false });
                setTimeout(() => (this.isChangingData = false), 300);
              }
            }
          });
        }
      });

      incomeProps = incomes.map(income => {
        return {
          ...income,
        };
      });
    });
 */

    // Replace by custom zip component, ok to remove after confirming no regressions there
    /**
    const properties = [
      propLocation.get('addressSubject'),
      propLocation.get('borrowerPrimary').get('addressCurrent'),
      propLocation.get('borrowerPrimary').get('addressMailing'),
      propLocation.get('borrowerPrimary').get('addressHistory'),
      propLocation.get('borrowerSecondary').get('addressCurrent'),
      propLocation.get('borrowerSecondary').get('addressMailing'),
      propLocation.get('borrowerSecondary').get('addressHistory'),
    ];

    // Loop through all props
    properties.forEach(property => {

      // When state name changes, empty out zipcode and city name
      subs.push(
        property
          .get('stateName')
          .valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
          )
          .subscribe(() => {
            console.warn('test');
            if (!this.isChangingData) {
              this.isChangingData = true;
              property.get('zipCode').setValue(null);
              // property.get('cityName').setValue(null);
              // property.get('countyName').setValue(null);
              setTimeout(() => (this.isChangingData = false), 300);
            }
          }),
      );
       */
    /**
      // When zip code changes, pull lookup service and patch in results
      subs.push(
        property
          .get('zipCode')
          .valueChanges.pipe(
            debounceTime(300),
            filter(zipcode => (zipcode && zipcode.length === 5)),
          )
          .subscribe(zipcode => {
            this.api.services.zipCodeLookup(zipcode, this.settings.loUserIdOriginal).subscribe(res => {
              if (res) {
                // TODO: Add some defensive coding around checking for subject address in different state than
                // current address when reloading app and current address already filled out
                // The zip lookup response is necessary to check for servicable zip codes
                if (!this.isChangingData) {
                  this.isChangingData = true;
                  loanForm
                    .get('loan')
                    .get('$$custom')
                    .get('zipLookupResponse')
                    .patchValue(res);
                  property.get('cityName').patchValue(res.cityName, { emitEvent: false });
                  property.get('stateName').patchValue(res.stateName);
                  property.get('countyName').patchValue(res.countyName, { emitEvent: false });
                  setTimeout(() => (this.isChangingData = false), 300);
                }
              }
            });
          }),
      );

    });
     */
  }

  /**
   * Automatically prefill city/state when the zip code is filled out
   * Adds a subscriber to all zipcodes that uses a lookup service
   * @param loanForm
   */
  public financialInstutionAutoFill(loanForm: FormGroup, subs: Subscription[]) {
    // Get all properties
    const borrowers = loanForm
      .get('loan')
      .get('transactionInfo')
      .get('borrowers') as FormArray;
    borrowers.controls.forEach(borrower => {
      const assets = borrower.get('assets') as FormArray;
      console.log('borrower', assets);
      subs.push(
        assets.valueChanges.subscribe(res => {
          console.log('Changes to assets', res);
        }),
      );
    });
  }

  /**
   * Manages the spousal current address toggle
   * @param loanForm
   * @param subs
   */
  public spouseAddressAutoFill(loanForm: FormGroup, subs: Subscription[]) {
    // Get is same address property
    const isSameAddress = loanForm.get('loan.$$custom.loan.borrowerSecondary.isAddressSameAsPrimaryBorrower');
    // Get secondary borrower prop
    const spouseAddress = loanForm.get('loan.$$custom.loan.borrowerSecondary.addressCurrent');

    // When the same address prop changes
    subs.push(
      isSameAddress.valueChanges.subscribe(val => {
        if (val) {
          // Patch primary borrower address in and disable control
          this.isChangingData = true;
          this.spouseAddressPatchValue(loanForm);
          spouseAddress.disable();
          setTimeout(() => (this.isChangingData = false), 300);
        } else {
          spouseAddress.enable();
        }
      }),
    );
    // we registered a change handler... but we need to actually initialize the fields
    isSameAddress.updateValueAndValidity({ onlySelf: false, emitEvent: true });
  }

  public spouseAddressPatchValue(loanForm: FormGroup) {
    // Get primary borrower prop
    const borrowerAddress = loanForm
      .get('loan')
      .get('$$custom')
      .get('loan')
      .get('borrowerPrimary')
      .get('addressCurrent');

    // Get secondary borrower prop
    const spouseAddress = loanForm
      .get('loan')
      .get('$$custom')
      .get('loan')
      .get('borrowerSecondary')
      .get('addressCurrent');

    const borrowerAddressValue = borrowerAddress.value;
    const spouseAddressValue = loanForm.getRawValue().loan.$$custom.loan.borrowerSecondary.addressCurrent;
    spouseAddress.patchValue(
      {
        ...spouseAddressValue,
        streetName: borrowerAddressValue.streetName,
        unitNumber: borrowerAddressValue.unitNumber,
        cityName: borrowerAddressValue.cityName,
        stateName: borrowerAddressValue.stateName,
        zipCode: borrowerAddressValue.zipCode,
        countyName: borrowerAddressValue.countyName,
      },
      { emitEvent: false },
    );
  }

  /**
   * Manage logic toggle SSNs
   * @param loanForm
   * @param subs
   */
  public ssnValidation(loanForm: FormGroup, subs: Subscription[]) {
    const ssn1 = loanForm.get('loan.$$custom.ssn');
    const ssn1Confirm = loanForm.get('loan.transactionInfo.borrowers.0.ssn');
    const creditAuth = loanForm.get('loan.transactionInfo.loanApplications.0.isRunCreditAuthorized');
    const eVoiVoeAuth = loanForm.get('loan.$$custom.isRunEvoiEvoeAuthorized');

    const ssn2 = loanForm.get('loan.transactionInfo.borrowers.1.ssn');
    const ssn2Confirm = loanForm.get('loan.$$custom.ssnSpouse');
    const creditAuth2 = loanForm.get('loan.$$custom.isRunCreditAuthorizedSpouse');
    const eVoiVoeAuth2 = loanForm.get('loan.$$custom.isRunEvoiEvoeAuthorizedSpouse');

    creditAuth.disable();
    creditAuth2.disable();
    eVoiVoeAuth.disable();
    eVoiVoeAuth2.disable();

    // On load, determine whether or not credit should be enabled or not
    if (ssn1.value && ssn1.value.length === 9 && ssn1.value === ssn1Confirm.value) {
      creditAuth.enable();
      eVoiVoeAuth.enable();
      eVoiVoeAuth.markAsUntouched();
    }

    if (ssn2.value && ssn2.value.length === 9 && ssn2.value === ssn2Confirm.value) {
      creditAuth2.enable();
      eVoiVoeAuth2.enable();
      eVoiVoeAuth2.markAsUntouched();
    }

    subs.push(
      merge(ssn1.valueChanges, ssn1Confirm.valueChanges).subscribe(() => {
        if (ssn1.value && ssn1.value.length === 9 && ssn1.value === ssn1Confirm.value) {
          creditAuth.enable(); // If both SSN's match, enable control
          eVoiVoeAuth.enable();
          eVoiVoeAuth.markAsUntouched();
        } else {
          creditAuth.disable(); // Keep disabled if ssn's do not match
          // On any ssn changes, reset credit auth
          creditAuth.patchValue(null);
          eVoiVoeAuth.disable();
          eVoiVoeAuth.patchValue(null);
        }
      }),

      merge(ssn2.valueChanges, ssn2Confirm.valueChanges).subscribe(() => {
        if (ssn2.value && ssn2.value.length === 9 && ssn2.value === ssn2Confirm.value) {
          creditAuth2.enable(); // If both SSN's match, enable control
          eVoiVoeAuth2.enable();
          eVoiVoeAuth2.markAsUntouched();
        } else {
          creditAuth2.disable(); // Keep disabled if ssn's do not match
          // On any ssn changes, reset credit auth
          creditAuth2.patchValue(null);
          eVoiVoeAuth2.disable();
          eVoiVoeAuth2.patchValue(null);
        }
      }),
    );
  }
}
